import { FC } from "react";
import { useRecordContext } from "react-admin";
import { deleteInstance } from "../services/data-provider";

const DeleteButton: FC = () => {
  const record = useRecordContext();
  const device_id = record?.device_id;
  const status = record?.status;
  if (!device_id || status !== "Running") {
    return null;
  }
  const onClick = async () => {
    if (confirm("Are you sure you want to delete this device?")) {
      await deleteInstance(device_id);
    }
  };
  return <button onClick={onClick}>Delete</button>;
};

export default DeleteButton;
