import { FC } from "react";
import { useRecordContext } from "react-admin";
import { pauseResumeInstance } from "../services/data-provider";

const ResizeButton: FC = () => {
  const record = useRecordContext();
  const status = record?.status;
  const inference_instance_id = record.inference_instance_id;

  const action = status === "Running" ? "pause" : "resume";
  const label = status === "Running" ? "Pause" : "Resume";
  const onClick = async () => {
    if (confirm(`Are you sure you want to ${action} this device?`)) {
      await pauseResumeInstance(inference_instance_id, action);
    }
  };
  return <button onClick={onClick}>{label}</button>;
};

export default ResizeButton;
